
import * as Phaser from "phaser";
import * as spine from "@esotericsoftware/spine-phaser";

const cardImages = {
  Common: "/assets/noi/c02.png",
  Uncommon: "/assets/noi/bf02.png",
  Rare: "/assets/noi/sp02.png",
  Epic: "/assets/noi/wk02.png",
  legendery: "/assets/noi/wk02.png",
};
const prizeToImage = {
  Cash: "/assets/cards-icons/cash.png",
  "Bonus Funds": "/assets/cards-icons/bonus_funds.png",
  "Free Spins": "/assets/cards-icons/free_spins.png",
  "Free Bet": "/assets/cards-icons/free_bets.png",
  FunCoins: "/assets/cards-icons/win_coins.png",
  "Golden Chips": "/assets/cards-icons/golden_chips.png",
  "Wheel Spins": "/assets/cards-icons/wheel_spins.png",
  "Loyalty Points": "/assets/cards-icons/loyalty_points.png",
  "Shop Points": "/assets/cards-icons/shop_points.png",
  Multiplier: "/assets/cards-icons/multiplier.png",
};
const loot = document.querySelector(".loot");

export class LootboxSpine extends Phaser.Scene {
  constructor() {
    super({ key: "LootboxSpine" });
    this.isAnimationPlaying = false;
    this.cardIndex = 0;
    this.openedCards = [];
    this.gameOver = false;
    this.selectedPullType = null;
    this.hasMultiplier = false;
    this.numRounds = 0;
    this.isLegendary = false;
    this.animaionSkip = false;
    this.isSvgAdded = false;
    this.isFirstClick = true;
    this.isLegendaryAnimationPlaying = false;
    this.lastWidth = window.innerWidth;
    this.lastHeight = window.innerHeight;
    this.resizeCheckInterval = null;
    this.isDisplayAllCardsInitialized = false;
    this.isSkipAnimation = false;
    this.animationDestroyed = false;
    this.isPremiumAnimationActive = false;
    
    
  }

  preload() {
    this.load.image("particle", "assets/white-flare.png");

    const urlParams = new URLSearchParams(window.location.search);
    let chestNumber = parseInt(urlParams.get("chest"), 10);

    if (!chestNumber || chestNumber < 1 || chestNumber > 5) {
      chestNumber = 1;
    }

    console.log("chestNumber", chestNumber);
    const chestPath = `assets/chest_${chestNumber}/`;

    this.load.spineBinary("lootbox-data", `${chestPath}skeleton.skel`);
    this.load.spineAtlas("lootbox-atlas", `${chestPath}export.atlas`);
    this.load.audio("open2Sound", `/assets/chest2.mp3`);
    this.load.audio("cash", `/assets/cash.mp3`);
    this.load.audio("spin", `/assets/paper-spin-86544.mp3`);
    this.load.audio("boom", `/assets/spellcast-46164.mp3`);
    this.load.audio("sumar", `/assets/sumar2.mp3`);
    this.load.audio("legendary", `/assets/legendary.mp3`);
    this.load.audio("clap", `/assets/clap3.mp3`);
    this.load.audio("get_card", `/assets/get_card.mp3`);
    this.load.audio("loop", `/assets/loop.mp3`);

    this.load.spineBinary(
      "particle-explosion-data",
      "assets/chest_particle_explosion_v3/skeleton.skel"
    );
    this.load.spineAtlas(
      "particle-explosion-atlas",
      "assets/chest_particle_explosion_v3/card_particles_explode_export.atlas"
    );
    this.load.spineBinary(
      "particle-implosion-data",
      "assets/particles_implosion_v3/skeleton.skel"
    );
    this.load.spineAtlas(
      "particle-implosion-atlas",
      "assets/particles_implosion_v3/card_particles_export.atlas"
    );

    this.load.spineBinary(
      "premium-data",
      "assets/frame_and particles_v2/crd_spin.skel"
    );
    this.load.spineAtlas(
      "premium-atlas",
      "assets/frame_and particles_v2/card_particles_v3.atlas"
    );

    this.load.image("card_1", "/assets/card_numbered_2.png");
    this.load.image("card_2", "/assets/card_numbered_2.png");
    this.load.image("legendaryCard", "/assets/cardback_legendary_question.png");
    this.load.image(
      "legendaryCardDesktop",
      "/assets/cardback_legendary_question_desktop.png"
    );
    this.load.image("newLegendaryImage", "/assets/cards-bg/card_legendary.png");
    this.load.image("freeSpinsIcon", "assets/cards-icons/free_spins.png");
    this.load.image(
      "freeSpinsIconDesktop",
      "assets/cards-icons/free_spins_desktop.png"
    );
  }

  create() {
    const self = this;
    this.sound.pauseOnBlur = false;
    const muteButton = document.getElementById("muteButton");
    let isMuted = false;
    muteButton.addEventListener("click", () => {
      isMuted = !isMuted;
      this.sound.mute = isMuted;
      muteButton.classList.toggle("muted", isMuted);
    });
    const centerX = this.cameras.main.width / 2;
    const centerY = this.cameras.main.height / 2;

    let lootboxX = centerX + 100;
    let lootboxY = centerY + 260;

    if (window.innerWidth < 1024) {
      lootboxX = centerX;
      lootboxY = centerY + 950;
    }
    this.fpsText = this.add.text(10, -710, "FPS: 0", {
      font: "76px Arial",
      fill: "#00ff00",
    });

    const lootbox = this.add.spine(
      lootboxX,
      lootboxY,
      "lootbox-data",
      "lootbox-atlas"
    );
    this.resizeLootbox(lootbox);
    window.addEventListener("resize", () => {
      this.resizeLootbox(lootbox);
    });
    this.startResizeCheck(lootbox);
    if (this.isSkipAnimation ) {
      console.log("cica merge");
    }
    if (window.innerWidth <= 1024 && !this.sys.game.device.os.iPad) {
      console.log("ipad 6");
      this.legendaryCard = this.add.plane(
        this.sys.scale.width / 2,
        this.sys.scale.height / 2,
        "legendaryCard"
      );

      this.legendaryCard.setScale(0.5);
      this.legendaryCard.setVisible(false);

      this.animateLegendaryCard = () => {
        this.legendaryCard.setVisible(true);
        const particleImplosion = this.add.spine(
          this.legendaryCard.x,
          this.legendaryCard.y,
          "particle-implosion-data",
          "particle-implosion-atlas"
        );
        particleImplosion.setVisible(true);
        particleImplosion.setScale(2);
        this.playAnimation(particleImplosion, "animation", true);

        const timeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.4,
              scaleY: 0.4,
              y: this.sys.scale.height / 3 - 20,
              duration: 800,
              ease: "Cubic.easeOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              y: this.sys.scale.height / 3 - 570,
              scaleX: 0.88,
              scaleY: 0.88,
              duration: 3000,
              ease: "Sine.easeInOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              rotateY: -7200,
              duration: 4680,
              ease: (t) => {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
              },
            },
          },
          {
            at: 5800,
            run: () => {
              particleImplosion.setVisible(false);

              this.addJelloAnimation();
            },
          },
        ]);
        const checkAndDestroy = () => {
          if (this.isSkipAnimation && !this.animationDestroyed) {
            timeline.stop();
            particleImplosion.destroy();
            this.legendaryCard.setVisible(false);
            console.log("Legendary timeline destroyed due to skip animation");

            this.animationDestroyed = true;
            this.showFinalLegendaryAnimation();
          }
        };

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });
        timeline.play();
      };

      this.addJelloAnimation = () => {
        let freeSpinsIcon;
        let freeSpinsText;

        const jelloTimeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.25,
              scaleY: 0.75,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 150,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.75,
              scaleY: 1.25,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 250,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.15,
              scaleY: 0.85,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },

          {
            at: 350,
            run: () => {
              this.legendaryCard.setTexture("newLegendaryImage");

              const cardX = this.legendaryCard.x;
              const cardY = this.legendaryCard.y;

              freeSpinsIcon = this.add.image(
                cardX,
                cardY - 15,
                "freeSpinsIcon"
              );
              freeSpinsIcon.setScale(0);
              freeSpinsIcon.setDepth(this.legendaryCard.depth + 1);

              freeSpinsText = this.add.text(
                cardX,
                cardY + 380,
                this.won_amount,
                {
                  fontFamily: "Berlin Sans FB Demi",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#ffffff",
                  align: "center",
                  lineSpacing: 1,
                  letterSpacing: -3,
                  stroke: "#000000",
                  strokeThickness: 1,
                  shadow: {
                    offsetY: 1.843,
                    color: "#000000",
                    blur: 0,
                    stroke: true,
                    fill: true,
                  },
                }
              );
              freeSpinsText.setOrigin(0.5);
              freeSpinsText.setDepth(this.legendaryCard.depth + 1);
              freeSpinsText.setScale(0);

              this.tweens.add({
                targets: [freeSpinsIcon],
                scale: 0.86,
                duration: 300,
                ease: "Back.easeOut",
              });
              this.tweens.add({
                targets: [freeSpinsText],
                scale: 8,
                duration: 300,
                ease: "Back.easeOut",
              });
            },
          },
          {
            at: 350,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.95,
              scaleY: 1.05,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 500,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.05,
              scaleY: 0.95,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 600,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.88,
              scaleY: 0.88,
              duration: 250,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 950,
            run: () => {
              if (this.legendaryCard && freeSpinsIcon && freeSpinsText) {
                const screenWidth = this.sys.game.config.width;
                const margin = screenWidth * 0.1;
                const targetX = -margin;
                const currentX = this.legendaryCard.x;
                const distanceToMove = currentX - targetX;

                this.tweens.add({
                  targets: [this.legendaryCard, freeSpinsIcon, freeSpinsText],
                  x: `-=${distanceToMove}`,
                  duration: 500,
                  ease: "Power2",
                  onComplete: () => {
                    this.activatePremiumAnimation();
                  },
                });
              }
            },
          },
        ]);
        const checkAndDestroy = () => {
          if (this.isSkipAnimation ) {
            timeline.stop();
            if (freeSpinsText) freeSpinsText.setVisible(false);
            if (freeSpinsIcon) freeSpinsIcon.setVisible(false);

          }
        };

   

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });
        jelloTimeline.play();
        setTimeout(() => {
          freeSpinsText.setVisible(false);
          freeSpinsIcon.setVisible(false);
        }, 4200);
      };
    } else if (this.sys.game.device.os.iPad && window.innerWidth <= 1024) {
      console.log("iPad 7");

      this.legendaryCard = this.add.plane(
        lootboxX - 100,
        lootboxY,
        "legendaryCardDesktop"
      );

      this.legendaryCard.setScale(0.5);
      this.legendaryCard.setVisible(false);

      this.animateLegendaryCard = () => {
        this.checkSkipAnimation();
        if (this.isSkipAnimation) {
          console.log("skip animation legendary");
          this.legendaryCard.setVisible(false);
          return;
        }
        this.legendaryCard.setVisible(true);
        const particleImplosion = this.add.spine(
          this.legendaryCard.x,
          this.legendaryCard.y,
          "particle-implosion-data",
          "particle-implosion-atlas"
        );
        particleImplosion.setVisible(true);
        particleImplosion.setScale(0.5);
        this.playAnimation(particleImplosion, "animation", true);

        const timeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1,
              scaleY: 1,
              y: this.sys.scale.height / 3 + 220,
              duration: 800,
              ease: "Cubic.easeOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              y: this.sys.scale.height / 3 + 80,
              scaleX: 2,
              scaleY: 2,
              duration: 3000,
              ease: "Sine.easeInOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              rotateY: -7200,
              duration: 4680,
              ease: (t) => {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
              },
            },
          },
          {
            at: 5800,
            run: () => {
              particleImplosion.setVisible(false);

              this.addJelloAnimation();
            },
          },
        ]);
        const checkAndDestroy = () => {
          if (this.isSkipAnimation && !this.animationDestroyed) {
            timeline.stop();
            particleImplosion.destroy();
            this.legendaryCard.setVisible(false);
            console.log("Legendary timeline destroyed due to skip animation");

            this.animationDestroyed = true;
            this.showFinalLegendaryAnimation();
          }
        };

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });

        timeline.play();
      };

      this.addJelloAnimation = () => {
        let freeSpinsIcon;
        let freeSpinsText;

        const jelloTimeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.25,
              scaleY: 0.75,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 150,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.75,
              scaleY: 1.25,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 250,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.15,
              scaleY: 0.85,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },

          {
            at: 350,
            run: () => {
              this.legendaryCard.setTexture("newLegendaryImage");

              const cardX = this.legendaryCard.x;
              const cardY = this.legendaryCard.y;

              freeSpinsIcon = this.add.image(
                cardX,
                cardY,
                "freeSpinsIconDesktop"
              );
              freeSpinsIcon.setScale(0);
              freeSpinsIcon.setDepth(this.legendaryCard.depth + 1);

              freeSpinsText = this.add.text(
                cardX - 50,
                cardY + 160,
                this.won_amount,
                {
                  fontFamily: "Berlin Sans FB Demi",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#ffffff",
                  align: "center",
                  lineSpacing: 1,
                  letterSpacing: -3,
                  stroke: "#000000",
                  strokeThickness: 1,
                  shadow: {
                    offsetY: 1.843,
                    color: "#000000",
                    blur: 0,
                    stroke: true,
                    fill: true,
                  },
                }
              );
              freeSpinsText.setOrigin(0.2);
              freeSpinsText.setDepth(this.legendaryCard.depth + 1);
              freeSpinsText.setScale(0);

              this.tweens.add({
                targets: [freeSpinsIcon],
                scale: 2,
                duration: 300,
                ease: "Back.easeOut",
              });
              this.tweens.add({
                targets: [freeSpinsText],
                scale: 2.5,
                duration: 300,
                ease: "Back.easeOut",
              });
            },
          },
          {
            at: 350,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.95,
              scaleY: 1.05,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 500,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.05,
              scaleY: 0.95,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 600,
            tween: {
              targets: this.legendaryCard,
              scaleX: 2,
              scaleY: 2,
              duration: 250,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 950,
            run: () => {
              if (this.legendaryCard && freeSpinsIcon && freeSpinsText) {
                this.tweens.add({
                  targets: [this.legendaryCard, freeSpinsIcon, freeSpinsText],
                  x: "-=400",
                  duration: 500,
                  ease: "Power2",
                  onComplete: () => {
                    this.activatePremiumAnimation();
                  },
                });
              }
            },
          },
        ]);

        const checkAndDestroy = () => {
          if (this.isSkipAnimation) {
            jelloTimeline.stop();
            if (freeSpinsText) freeSpinsText.setVisible(false);
            if (freeSpinsIcon) freeSpinsIcon.setVisible(false);

            console.log("Jello timeline destroyed due to skip animation");
          }
        };

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });
        jelloTimeline.play();

        setTimeout(() => {
          freeSpinsText.setVisible(false);
          freeSpinsIcon.setVisible(false);
        }, 4200);
      };
    } else if (
      globalDeviceInfo.smallerSide === 1024 &&
      this.sys.game.device.os.iPad
    ) {
      console.log("iPad6");
      this.legendaryCard = this.add.plane(
        lootboxX,
        lootboxY,
        "legendaryCardDesktop"
      );

      this.legendaryCard.setScale(0.5);
      this.legendaryCard.setVisible(false);

      this.animateLegendaryCard = () => {
        this.checkSkipAnimation();
        if (this.isSkipAnimation) {
          console.log("skip animation legendary");
          this.legendaryCard.setVisible(false);
          return;
        }
        this.legendaryCard.setVisible(true);
        const particleImplosion = this.add.spine(
          this.legendaryCard.x,
          this.legendaryCard.y,
          "particle-implosion-data",
          "particle-implosion-atlas"
        );
        particleImplosion.setVisible(true);
        particleImplosion.setScale(0.5);
        this.playAnimation(particleImplosion, "animation", true);

        const timeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1,
              scaleY: 1,
              y: this.sys.scale.height / 3 + 220,
              duration: 800,
              ease: "Cubic.easeOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              y: this.sys.scale.height / 3 + 80,
              scaleX: 2,
              scaleY: 2,
              duration: 3000,
              ease: "Sine.easeInOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              rotateY: -7200,
              duration: 4680,
              ease: (t) => {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
              },
            },
          },
          {
            at: 5800,
            run: () => {
              particleImplosion.setVisible(false);

              this.addJelloAnimation();
            },
          },
        ]);
        const checkAndDestroy = () => {
          if (this.isSkipAnimation && !this.animationDestroyed) {
            timeline.stop();
            particleImplosion.destroy();
            this.legendaryCard.setVisible(false);
            console.log("Legendary timeline destroyed due to skip animation");

            this.animationDestroyed = true;
            this.showFinalLegendaryAnimation();
          }
        };

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });

        timeline.play();
      };

      this.addJelloAnimation = () => {
        let freeSpinsIcon;
        let freeSpinsText;

        const jelloTimeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.25,
              scaleY: 0.75,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 150,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.75,
              scaleY: 1.25,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 250,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.15,
              scaleY: 0.85,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },

          {
            at: 350,
            run: () => {
              this.legendaryCard.setTexture("newLegendaryImage");

              const cardX = this.legendaryCard.x;
              const cardY = this.legendaryCard.y;

              freeSpinsIcon = this.add.image(
                cardX,
                cardY,
                "freeSpinsIconDesktop"
              );
              freeSpinsIcon.setScale(0);
              freeSpinsIcon.setDepth(this.legendaryCard.depth + 1);

              freeSpinsText = this.add.text(
                cardX - 50,
                cardY + 160,
                this.won_amount,
                {
                  fontFamily: "Berlin Sans FB Demi",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#ffffff",
                  align: "center",
                  lineSpacing: 1,
                  letterSpacing: -3,
                  stroke: "#000000",
                  strokeThickness: 1,
                  shadow: {
                    offsetY: 1.843,
                    color: "#000000",
                    blur: 0,
                    stroke: true,
                    fill: true,
                  },
                }
              );
              freeSpinsText.setOrigin(0.2);
              freeSpinsText.setDepth(this.legendaryCard.depth + 1);
              freeSpinsText.setScale(0);

              this.tweens.add({
                targets: [freeSpinsIcon],
                scale: 2,
                duration: 300,
                ease: "Back.easeOut",
              });
              this.tweens.add({
                targets: [freeSpinsText],
                scale: 2.5,
                duration: 300,
                ease: "Back.easeOut",
              });
            },
          },
          {
            at: 350,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.95,
              scaleY: 1.05,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 500,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.05,
              scaleY: 0.95,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 600,
            tween: {
              targets: this.legendaryCard,
              scaleX: 2,
              scaleY: 2,
              duration: 250,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 950,
            run: () => {
              if (this.legendaryCard && freeSpinsIcon && freeSpinsText) {
                this.tweens.add({
                  targets: [this.legendaryCard, freeSpinsIcon, freeSpinsText],
                  x: "-=450",
                  duration: 500,
                  ease: "Power2",
                  onComplete: () => {
                    this.activatePremiumAnimation();
                  },
                });
              }
            },
          },
        ]);

        const checkAndDestroy = () => {
          if (this.isSkipAnimation) {
            jelloTimeline.stop();
            if (freeSpinsText) freeSpinsText.setVisible(false);
            if (freeSpinsIcon) freeSpinsIcon.setVisible(false);

          }
        };

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });
        jelloTimeline.play();

        setTimeout(() => {
          freeSpinsText.setVisible(false);
          freeSpinsIcon.setVisible(false);
        }, 4200);
      };
    } else {
      if (this.sys.game.device.os.iPad) {
        console.log("iPad3");
      }
      this.legendaryCard = this.add.plane(
        lootboxX,
        lootboxY,
        "legendaryCardDesktop"
      );
      
      this.legendaryCard.setScale(0.5);
      this.legendaryCard.setVisible(false);

      this.animateLegendaryCard = () => {
        
        this.checkSkipAnimation();
        if (this.isSkipAnimation) {
          console.log("skip animation legendary");
          this.legendaryCard.setVisible(false);
          return;
        }
        this.legendaryCard.setVisible(true);
        const particleImplosion = this.add.spine(
          this.legendaryCard.x,
          this.legendaryCard.y,
          "particle-implosion-data",
          "particle-implosion-atlas"
        );
        particleImplosion.setVisible(true);
        particleImplosion.setScale(0.2);
        this.playAnimation(particleImplosion, "animation", true);

        const timeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.4,
              scaleY: 0.4,
              y: this.sys.scale.height / 3 + 220,
              duration: 800,
              ease: "Cubic.easeOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              y: this.sys.scale.height / 3 + 80,
              scaleX: 0.6,
              scaleY: 0.6,
              duration: 3000,
              ease: "Sine.easeInOut",
              onUpdate: () => {
                particleImplosion.setPosition(
                  this.legendaryCard.x,
                  this.legendaryCard.y
                );
              },
            },
          },
          {
            at: 800,
            tween: {
              targets: this.legendaryCard,
              rotateY: -7200,
              duration: 4680,
              ease: (t) => {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
              },
            },
          },
          {
            at: 5800,
            run: () => {
              particleImplosion.setVisible(false);

              this.addJelloAnimation();
            },
          },
        ]);
        const checkAndDestroy = () => {
          if (this.isSkipAnimation && !this.animationDestroyed) {
            timeline.stop();
            particleImplosion.destroy();
            this.legendaryCard.setVisible(false);
            console.log("a mersss");
            this.animationDestroyed = true;
            this.showFinalLegendaryAnimation();
          }
        };

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });
        
        timeline.play();
      
      };

      this.addJelloAnimation = () => {
        let freeSpinsIcon;
        let freeSpinsText;

        const jelloTimeline = this.add.timeline([
          {
            at: 0,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.25,
              scaleY: 0.75,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 150,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.75,
              scaleY: 1.25,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 250,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.15,
              scaleY: 0.85,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },

          {
            at: 350,
            run: () => {
              this.legendaryCard.setTexture("newLegendaryImage");

              const cardX = this.legendaryCard.x;
              const cardY = this.legendaryCard.y;

              freeSpinsIcon = this.add.image(
                cardX,
                cardY,
                "freeSpinsIconDesktop"
              );
              freeSpinsIcon.setScale(0);
              freeSpinsIcon.setDepth(this.legendaryCard.depth + 1);

              freeSpinsText = this.add.text(
                cardX - 15,
                cardY + 60,
                this.won_amount,
                {
                  fontFamily: "Berlin Sans FB Demi",
                  fontSize: "26px",
                  fontStyle: "normal",
                  fontWeight: "700",
                  color: "#ffffff",
                  align: "center",
                  lineSpacing: 1,
                  letterSpacing: -3,
                  stroke: "#000000",
                  strokeThickness: 1,
                  shadow: {
                    offsetY: 1.843,
                    color: "#000000",
                    blur: 0,
                    stroke: true,
                    fill: true,
                  },
                }
              );
              freeSpinsText.setOrigin(0.2);
              freeSpinsText.setDepth(this.legendaryCard.depth + 1);
              freeSpinsText.setScale(0);

              this.tweens.add({
                targets: [freeSpinsIcon],
                scale: 0.7,
                duration: 300,
                ease: "Back.easeOut",
              });
              this.tweens.add({
                targets: [freeSpinsText],
                scale: 1,
                duration: 300,
                ease: "Back.easeOut",
              });
            },
          },
          {
            at: 350,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.95,
              scaleY: 1.05,
              duration: 150,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 500,
            tween: {
              targets: this.legendaryCard,
              scaleX: 1.05,
              scaleY: 0.95,
              duration: 100,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 600,
            tween: {
              targets: this.legendaryCard,
              scaleX: 0.7,
              scaleY: 0.7,
              duration: 250,
              ease: "Sine.easeInOut",
            },
          },
          {
            at: 950,
            run: () => {
              if (this.legendaryCard && freeSpinsIcon && freeSpinsText) {
                this.tweens.add({
                  targets: [this.legendaryCard, freeSpinsIcon, freeSpinsText],
                  x: "-=200",
                  duration: 500,
                  ease: "Power2",
                  onComplete: () => {
                    this.activatePremiumAnimation();
                  },
                });
              }
            },
          },
        ]);

        // const checkAndDestroy = () => {
        //   if (this.isSkipAnimation) {
        //     jelloTimeline.stop();
        //     // timeline.stop();
        //     // this.freeSpinsText[1].destroy();
        //     // this.freeSpinsIcon[1].destroy();
        //     console.log("Jello timeline destroyed due to skip animation");
        //   }
        // };
        const checkAndDestroy = () => {
          if (this.isSkipAnimation ) {
            jelloTimeline.stop();
   
            if (freeSpinsText) freeSpinsText.setVisible(false);
            if (freeSpinsIcon) freeSpinsIcon.setVisible(false);

        
            console.log("Jello timeline destroyed due to skip animation");
        
          }
        };

        this.time.addEvent({
          delay: 100,
          loop: true,
          callback: checkAndDestroy,
          callbackScope: this,
        });
        jelloTimeline.play();

        setTimeout(() => {
          freeSpinsText.setVisible(false);
          freeSpinsIcon.setVisible(false);
        }, 4200);
      };
    }
    this.legendaryCard = this.legendaryCard;

    if (globalDeviceInfo.smallerSide === 834) {
      this.card1 = this.add
        .image(centerX + 70, centerY + 360, "card_1")
        .setScale(0)
        .setAlpha(0);
      this.card2 = this.add
        .image(centerX + 100, centerY + 360, "card_2")
        .setScale(0)
        .setAlpha(0);

      this.card1.setScale(0.3);
      this.card2.setScale(0.3);
      this.card1.x -= 10;
      this.card1.y += 5;
      this.card1.angle = 345;
      this.roundsText = this.add
        .text(centerX + 100, centerY + 360, this.numRounds.toString(), {
          fontWeight: "bold",
          fontSize: "70px",
          color: "#fff",
          fontFamily: '"Berlin Sans FB Demi"',
          letterSpacing: "1.1px",
          shadow: {
            offsetX: 2,
            offsetY: 2,
            color: "#000",
            blur: 2,
            stroke: true,
            fill: true,
          },
          stroke: "#000",
          strokeThickness: 8,
        })
        .setOrigin(0.5)
        .setScale(0)
        .setAlpha(0);
    } else if (
      globalDeviceInfo.smallerSide === 820 &&
      this.sys.game.device.os.iPad
    ) {
      console.log("iPad9");
      this.card1 = this.add
        .image(centerX + 70, centerY + 340, "card_1")
        .setScale(0)
        .setAlpha(0);
      this.card2 = this.add
        .image(centerX + 80, centerY + 340, "card_2")
        .setScale(0)
        .setAlpha(0);

      this.card1.setScale(0.3);
      this.card2.setScale(0.3);
      this.card1.x -= 10;
      this.card1.y += 5;
      this.card1.angle = 345;
      this.roundsText = this.add
        .text(centerX + 80, centerY + 340, this.numRounds.toString(), {
          fontWeight: "bold",
          fontSize: "50px",
          color: "#fff",
          fontFamily: '"Berlin Sans FB Demi"',
          letterSpacing: "1.1px",
          shadow: {
            offsetX: 2,
            offsetY: 2,
            color: "#000",
            blur: 2,
            stroke: true,
            fill: true,
          },
          stroke: "#000",
          strokeThickness: 6,
        })
        .setOrigin(0.5)
        .setScale(0)
        .setAlpha(0);
    } else if (globalDeviceInfo.smallerSide === 982) {
      this.card1 = this.add
        .image(centerX + 130, centerY + 260, "card_1")
        .setScale(0)
        .setAlpha(0);
      this.card2 = this.add
        .image(centerX + 140, centerY + 260, "card_2")
        .setScale(0)
        .setAlpha(0);

      this.card1.setScale(0.3);
      this.card2.setScale(0.3);
      this.card1.x -= 10;
      this.card1.y += 5;
      this.card1.angle = 345;
      this.roundsText = this.add
        .text(centerX + 140, centerY + 260, this.numRounds.toString(), {
          fontWeight: "bold",
          fontSize: "50px",
          color: "#fff",
          fontFamily: '"Berlin Sans FB Demi"',
          letterSpacing: "1.1px",
          shadow: {
            offsetX: 2,
            offsetY: 2,
            color: "#000",
            blur: 2,
            stroke: true,
            fill: true,
          },
          stroke: "#000",
          strokeThickness: 6,
        })
        .setOrigin(0.5)
        .setScale(0)
        .setAlpha(0);
    } else if (
      globalDeviceInfo.smallerSide <= 1024 &&
      !this.sys.game.device.os.iPad
    ) {
      this.card1 = this.add
        .image(centerX + 120, centerY + 950, "card_1")
        .setScale(0)
        .setAlpha(0);
      this.card2 = this.add
        .image(centerX + 170, centerY + 950, "card_2")
        .setScale(0)
        .setAlpha(0);

      this.card1.setScale(2.3);
      this.card2.setScale(2.3);
      this.card1.x -= 30;
      this.card1.y += 5;
      this.card1.angle = 345;
      this.roundsText = this.add
        .text(centerX + 170, centerY + 950, this.numRounds.toString(), {
          fontWeight: "bold",
          fontSize: "80px",
          color: "#fff",
          fontFamily: '"Berlin Sans FB Demi"',
          letterSpacing: "1.1px",
          shadow: {
            offsetX: 2,
            offsetY: 2,
            color: "#000",
            blur: 2,
            stroke: true,
            fill: true,
          },
          stroke: "#000",
          strokeThickness: 10,
        })
        .setOrigin(0.5)
        .setScale(0)
        .setAlpha(0);
    } else if (
      globalDeviceInfo.smallerSide === 1024 &&
      this.sys.game.device.os.iPad
    ) {
      this.card1 = this.add
        .image(centerX + 100, centerY + 350, "card_1")
        .setScale(0)
        .setAlpha(0);
      this.card2 = this.add
        .image(centerX + 105, centerY + 350, "card_2")
        .setScale(0)
        .setAlpha(0);

      this.card1.x -= 10;
      this.card1.y += 5;
      this.card1.angle = 345;
      this.roundsText = this.add
        .text(centerX + 105, centerY + 350, "7", {
          fontWeight: "bold",
          fontSize: "40px",
          color: "#fff",
          fontFamily: '"Berlin Sans FB Demi"',
          letterSpacing: "1.1px",
          shadow: {
            offsetX: 2,
            offsetY: 2,
            color: "#000",
            blur: 2,
            stroke: true,
            fill: true,
          },
          stroke: "#000",
          strokeThickness: 2,
        })
        .setOrigin(0.5)
        .setScale(0)
        .setAlpha(0);
    } else {
      this.card1 = this.add
        .image(centerX + 135, centerY + 250, "card_1")
        .setScale(0)
        .setAlpha(0);
      this.card2 = this.add
        .image(centerX + 140, centerY + 250, "card_2")
        .setScale(0)
        .setAlpha(0);

      this.card1.x -= 10;
      this.card1.y += 5;
      this.card1.angle = 345;
      this.roundsText = this.add
        .text(centerX + 140, centerY + 250, "7", {
          fontWeight: "bold",
          fontSize: "40px",
          color: "#fff",
          fontFamily: '"Berlin Sans FB Demi"',
          letterSpacing: "1.1px",
          shadow: {
            offsetX: 2,
            offsetY: 2,
            color: "#000",
            blur: 2,
            stroke: true,
            fill: true,
          },
          stroke: "#000",
          strokeThickness: 2,
        })
        .setOrigin(0.5)
        .setScale(0)
        .setAlpha(0);
    }

    const particleExplosion = this.add.spine(
      this.legendaryCard.x,
      this.legendaryCard.y,
      "particle-explosion-data",
      "particle-explosion-atlas"
    );
    particleExplosion.setVisible(false);

    const targetWidth = 400;
    const targetHeight = 400;
    const scaleX = targetWidth / particleExplosion.width;
    const scaleY = targetHeight / particleExplosion.height;
    particleExplosion.setScale(scaleX, scaleY);
    particleExplosion.setPosition(centerX + 50, centerY + 180);

    const isAndroid = document.body.classList.contains("android");

    particleExplosion.y -= 200;

    console.log(
      "Screen dimensions:",
      this.cameras.main.width,
      this.cameras.main.height
    );
    document.getElementById("regularButton").addEventListener("click", () => {
      const buttonContainer = document.getElementById("buttonContainer");
      console.log("regular");
      this.selectedPullType = "regular";
      buttonContainer.classList.add("hide");
      this.fetchAndHandleRounds("regular");
    });
    document.getElementById("claim").addEventListener("touchstart", (e) => {
      location.reload();
    });

    document
      .getElementById("regularButton")
      .addEventListener("touchstart", (e) => {
        console.log("Regular button touched");
        const buttonContainer = document.getElementById("buttonContainer");
        console.log("regular");
        this.selectedPullType = "regular";
        buttonContainer.classList.add("hide");
        this.fetchAndHandleRounds("regular");
      });
    document.getElementById("uncommonButton").addEventListener("click", () => {
      console.log("uncommon");
      this.selectedPullType = "uncommon";
      const buttonContainer = document.getElementById("buttonContainer");
      buttonContainer.classList.add("hide");
      this.fetchAndHandleRounds("uncommon");
    });
    document
      .getElementById("uncommonButton")
      .addEventListener("touchstart", (e) => {
        console.log("uncommon");
        this.selectedPullType = "uncommon";
        const buttonContainer = document.getElementById("buttonContainer");
        buttonContainer.classList.add("hide");
        this.fetchAndHandleRounds("uncommon");
      });

    document
      .getElementById("multiplierButton")
      .addEventListener("click", () => {
        console.log("multiplier");
        this.selectedPullType = "multiplier";
        const buttonContainer = document.getElementById("buttonContainer");
        buttonContainer.classList.add("hide");

        this.fetchAndHandleRounds("multiplier");
      });
    document
      .getElementById("multiplierButton")
      .addEventListener("touchstart", (e) => {
        console.log("multiplier");
        this.selectedPullType = "multiplier";
        const buttonContainer = document.getElementById("buttonContainer");
        buttonContainer.classList.add("hide");

        this.fetchAndHandleRounds("multiplier");
      });
    document.getElementById("legendaryButton").addEventListener("click", () => {
      console.log("legendary");
      this.selectedPullType = "legendary";
      const buttonContainer = document.getElementById("buttonContainer");
      buttonContainer.classList.add("hide");
      this.fetchAndHandleRounds("legendary");
    });
    document
      .getElementById("legendaryButton")
      .addEventListener("touchstart", (e) => {
        console.log("legendary");
        this.selectedPullType = "legendary";
        const buttonContainer = document.getElementById("buttonContainer");
        buttonContainer.classList.add("hide");
        this.fetchAndHandleRounds("legendary");
      });

    if (this.sys.game.device.os.iPad) {
      console.log("RuleazÄƒ pe ipad");
      this.cameras.main.setZoom(1);
      // lootbox.setPosition(centerX, centerY + 150);
      var videobackgroundDiv = document.querySelector(".video-background");
      videobackgroundDiv.style.display = "none";
    }

    if (this.sys.game.device.os.macOS) {
      console.log("RuleazÄƒ pe nac");
      var videobackgroundDiv = document.querySelector(".video-background");
      videobackgroundDiv.style.display = "none";
    }
    if (this.sys.game.device.os.iPhone) {
      console.log("RuleazÄƒ pe iphone");
    }

    this.input.on("pointerdown", () => {
      // document.querySelector(".game-container").addEventListener("mousedown", () => {

      if (self.isLegendary) {
        
        // VerificÄƒm dacÄƒ este un card legendary
        if (!document.body.classList.contains("skip-animation")) {
          // AdaugÄƒ clasa skip-animation dacÄƒ nu existÄƒ deja
          document.body.classList.add("skip-animation");
          

        }
        // Nu permite scoaterea clasei pentru legendary
        return;
      }
        if (self.isAnimationPlaying) {
        document.body.classList.add("skip-animation");

        return;
      }
      if (!self.isAnimationPlaying) {
        document.body.classList.remove("skip-animation");
        
      }
      if (self.isAnimationPlaying || self.gameOver) {
        document.body.classList.remove("skip-animation");

        return;
      }

      if (this.numRounds <= 0) {
        console.log("No more rounds available.");
        return;
      }
      this.numRounds--;
      self.isAnimationPlaying = true;


      function animateValue(element, start, end, duration) {
        const isSkipAnimation =
          document.body.classList.contains("skip-animation");
          
        const adjustedDuration = isSkipAnimation ? 1 : duration;
        const range = end - start;
        const stepTime = 10;
        const steps = adjustedDuration / stepTime;
        const increment = range / steps;

        let current = start;
        const timer = setInterval(() => {
          if (document.body.classList.contains("skip-animation")) {
            element.textContent = end;
            clearInterval(timer);
            return;
          }

          current += increment;
          if (
            (increment > 0 && current > end) ||
            (increment < 0 && current < start)
          ) {
            element.textContent = end;
            clearInterval(timer);
          } else {
            element.textContent = Math.floor(current);
          }
        }, stepTime);
      }

      var videobackground = document.querySelector(".video-background video");
      var videobackgroundDiv = document.querySelector(".video-background");

      fetch(`https://api-dem.vercel.app/api/${this.selectedPullType}`)
        .then((response) => response.json())
        .then((data) => {
          const {
            card_type,
            prize,
            won_amount,
            currencySymbol,
            additional_value,
          } = data;
          const legendaryPrizeToImage = {
            ...prizeToImage,
            "Free Spins": "/assets/02_questionmark.png",
          };

          const currentPrizeToImage =
            card_type === "Legendary" ? legendaryPrizeToImage : prizeToImage;
          this.won_amount = data.won_amount;

          if (card_type === "Legendary") {
            self.isLegendary = true;
            if (this.isAnimationPlaying) {
              console.log("Legendary card aniamtion", self.isAnimationPlaying);
            }
           //condition if the animateLegendaryCard animation not started to play the animation
            // if (this.isSkipAnimation) {
            //   console.log("mergeee");
            //   this.showFinalLegendaryAnimation();
            // }
            this.animateLegendaryCard();

            function handleInteraction() {
              console.log("Interaction detected");
              if (self.isLegendary && !self.isPremiumAnimationActive) {
                console.log("isLegendary interaction");
                document.body.classList.add("skip-animation");

                self.isAnimationSkipped = true;
                console.log(
                  "isAnimationSkipped set to:",
                  self.isAnimationSkipped
                );

                particleExplosion.destroy();
                self.sound.stopAll();
              }
            }
            document.addEventListener("click", handleInteraction);
            document.addEventListener("touchstart", handleInteraction);
            console.log("Legendary card, isLegendary is true.");
            videobackground.play();

            setTimeout(() => {
              if (!document.body.classList.contains("skip-animation")) {
                this.sound.play("boom", { volume: 1 });
              }
            }, 4900);

            setTimeout(() => {
              particleExplosion.setVisible(true);
              particleExplosion.setPosition(
                this.legendaryCard.x,
                this.legendaryCard.y
              );
              if (window.innerWidth <= 1024) {
                particleExplosion.setScale(2);
              } else {
                particleExplosion.setScale(0.5);
              }
              self
                .playAnimation(particleExplosion, "animation", false)
                .then(() => {
                  particleExplosion.setVisible(false);
                });
            }, 5900);
            setTimeout(() => {
              videobackgroundDiv.remove();
            }, 6300);

            setTimeout(() => {
              document.querySelector(".container").style.zIndex = "1";
              const isAndroid = document.body.classList.contains("android");
            }, 1000);

            document.querySelector(".card-footer").style.display = "none";
            document.querySelector(".animationcard").style.display = "block";
          }

          const cardtwo = document.querySelector(".card");
          const cont = document.querySelector(".container");
          cardtwo.classList.remove("object", "new3");
          if (card_type === "Legendary") {
            cardtwo.classList.add("new3");
            cont.style.visibility = "hidden!important";
            console.log("Legendary card, applying new3 animation.");
          } else {
            cardtwo.classList.add("object");
          }
          const cardwins = document.querySelector(".cardwins");
          cardwins.classList.remove("hide");
          const additionalValueText =
            document.querySelector(".additional-value");
          const cardBottom = document.querySelector(".cardbottom");
          additionalValueText.textContent = "0";

          if (card_type === "Legendary") {
            let checkSkipAnimationInterval;
            const startAnimation = () => {
              animateValue(additionalValueText, 0, additional_value, 1000);
            };

            if (document.body.classList.contains("skip-animation")) {
              console.log(
                "Skip animation class is present, setting value quickly."
              );
              animateValue(additionalValueText, 0, additional_value, 1);
            } else {
              let elapsed = 0;
              checkSkipAnimationInterval = setInterval(() => {
                if (document.body.classList.contains("skip-animation")) {
                  clearInterval(checkSkipAnimationInterval);
                  animateValue(additionalValueText, 0, additional_value, 1);
                } else {
                  elapsed += 100;
                  if (elapsed >= 7200) {
                    clearInterval(checkSkipAnimationInterval);
                    startAnimation();
                  }
                }
              }, 100);
            }
          } else {
            if (document.body.classList.contains("skip-animation")) {
              animateValue(additionalValueText, 0, additional_value, 1);
            } else {
              animateValue(additionalValueText, 0, additional_value, 1000);
            }
          }
          const existingLeiDiv = document.querySelector(".lei-div");
          if (existingLeiDiv) {
            existingLeiDiv.remove();
          }
          if (prize === "Bonus Funds" || prize === "Free Bet") {
            const leiDiv = document.createElement("div");
            leiDiv.className = "lei-div";
            leiDiv.textContent = "Lei";

            additionalValueText.parentNode.insertBefore(
              leiDiv,
              additionalValueText.nextSibling
            );
          }

          const cardTypeElement = document.querySelector(".cardType");

          const cardContainerepic = cardTypeElement.parentNode;
          const existingContent = cardContainerepic.querySelector(
            ".additional-content"
          );

          if (existingContent) {
            cardContainerepic.removeChild(existingContent);
            cardTypeElement.style.background = "";

            cardTypeElement.style.border = "";
          }
          cardTypeElement.textContent = card_type;
          const newContentContainer = document.createElement("div");
          newContentContainer.className = "additional-content";
          newContentContainer.classList.add("slide-in-right");
          if (card_type === "Epic" && prize === "Multiplier") {
            cardTypeElement.style.background = "#A22CDA";
            cardTypeElement.style.border = "2px solid #5E00B6";
            const image = document.createElement("img");
            image.src = "/assets/racheta2.png";
            image.alt = "Multiplied";

            const additionalDiv = document.createElement("div");
            additionalDiv.textContent = "Multiplied";

            newContentContainer.appendChild(image);
            newContentContainer.appendChild(additionalDiv);

            cardContainerepic.appendChild(newContentContainer);
          }
          cardTypeElement.className = `cardType ${card_type.toLowerCase()}`;
          void cardTypeElement.offsetWidth;

          if (card_type === "Legendary") {
            cardTypeElement.classList.add("slide-in-right-delayed");
          } else {
            cardTypeElement.classList.add("slide-in-right");
          }
          const detailsMap = {
            Cash: "Your balance",
            "Bonus Funds": "Your balance",
            "Free Spins": "Your free spins",
            "Free Bets": "Total Free Bet",
            "Golden Chips": "Total Golden Chips",
            "Loyalty Points": "Your Loyalty Points",
            Multiplier: "All wins in this chest are multiplied x5",
            "Shop Points": "Your Shop Points",
            "Wheel Spins": "Spin the wheel multiple times!",
            FunCoins: "Total FunCoins",
          };
          const cardImageMap = {
            "Free Bets": "/assets/prize-icons/free-bets.png",
            "Free Spins": "/assets/prize-icons/free-spins.png",
            "Golden Chips": "/assets/prize-icons/golden-chips.png",
            "Loyalty Points": "/assets/prize-icons/loyalty-points.png",
            "Shop Points": "/assets/prize-icons/shop-points.png",
            FunCoins: "/assets/prize-icons/win-coins.png",
          };

          const cardDetailsElement = document.querySelector(".cardDetails");
          cardDetailsElement.textContent = detailsMap[prize] || "Prize Details";
          if (prize === "Multiplier") {
            console.log("Multiplier");
            cardDetailsElement.classList.add("Multiplier");
          }

          cardDetailsElement.classList.remove("slide-in-right");
          void cardDetailsElement.offsetWidth;

          if (card_type === "Legendary") {
            cardDetailsElement.classList.add("slide-in-right-delayed");
          } else {
            cardDetailsElement.classList.add("slide-in-right");
            cardDetailsElement.classList.add("slide-in-right");
          }
          const cardWinsImgElement = document.querySelector(".cardWinsImg");
          if (cardImageMap[prize]) {
            cardWinsImgElement.src = cardImageMap[prize];
            cardWinsImgElement.style.display = "";
          } else {
            cardWinsImgElement.style.display = "none";
          }

          const cardWinsElement = document.querySelector(".cardwins");
          if (prize === "Multiplier" || prize === "Wheel Spins") {
            cardWinsElement.style.display = "none";
          } else {
            cardWinsElement.style.display = "";
            cardWinsElement.classList.remove("slide-in-right");
            void cardWinsElement.offsetWidth;
            if (card_type === "Legendary") {
              cardWinsElement.classList.add("slide-in-right-delayed");
            } else {
              cardWinsElement.classList.add("slide-in-right");
            }
            if (card_type === "Legendary") {
              setTimeout(() => {}, 7200);
            } else {
              setTimeout(() => {}, 500);
            }
          }

          let imagePath = "/assets/noi/default.png";
          let backimagePath = "/assets/noi/default.png";
          const iconImagePath =
            currentPrizeToImage[prize] || "/assets/prizes/default-icon.png";

          switch (card_type) {
            case "Common":
              imagePath = "/assets/cards-bg/card_common.png";
              backimagePath = "/assets/card-back/cardback_common.png";
              break;
            case "Uncommon":
              imagePath = "/assets/cards-bg/card_uncommon.png";
              backimagePath = "/assets/card-back/cardback_uncommon.png";
              break;
            case "Rare":
              imagePath = "/assets/cards-bg/card_rare.png";
              backimagePath = "/assets/card-back/cardback_rare.png";
              break;
            case "Epic":
              imagePath = "/assets/cards-bg/card_epic.png";
              backimagePath = "/assets/card-back/cardback_epic.png";
              break;
            case "Legendary":
              imagePath = "/assets/card-back/cardback_legendary.png";
              backimagePath = "/assets/card-back/cardback_legendary.png";
              break;
          }

          const cardFrontImage = document.querySelector(".card .front img");
          cardFrontImage.src = imagePath;
          const cardFrontIcon = document.querySelector(
            ".card .front .front-icon"
          );
          if (cardFrontIcon) {
            cardFrontIcon.src = iconImagePath;
          }
          const cardBackImage = document.querySelector(".card .back img");
          cardBackImage.src = backimagePath;
          cardBackImage.style.filter = "brightness(0.5) invert(1)";

          setTimeout(() => {
            cardBackImage.style.filter = "none";
          }, 250);

          const prizeTextElement = document.querySelector(".textPrize");
          prizeTextElement.textContent = `${prize}`;
          if (
            this.selectedPullType === "multiplier" &&
            prize !== "Multiplier"
          ) {
            const multiplierSpan = document.createElement("span");
            multiplierSpan.textContent = " x5";

            const isMobile = window.innerWidth < 800;
            if (isMobile && prize === "Loyalty Points") {
              prizeTextElement.style.fontSize = "30px";
              multiplierSpan.className = "multiplier-class-small";
            } else {
              multiplierSpan.className = "multiplier-class";
            }

            prizeTextElement.appendChild(multiplierSpan);
          }

          if (won_amount.length > 10) {
            const cardFooter = document.querySelector(".card-footer");

            cardFooter.style.fontSizAdjust = "0.4";
          }
          const amountTextElement = document.querySelector(".card .card-txt");
          amountTextElement.textContent = `${won_amount} `;

          this.openedCards.push({
            imagePath,
            iconImagePath,
            prize,
            won_amount,
            currencySymbol,
          });
          if (prize === "Multiplier") {
            this.hasMultiplier = true;
          }
          if (card_type === "Legendary") {
            console.log("Legendary");
            this.sound.play("legendary", { volume: 0.5 });
          } else {
            this.sound.play("get_card", { volume: 0.5 });
          }

          this.cardIndex = (this.cardIndex + 1) % cardImages.length;
          self.playAnimation(lootbox, "open2", false).then(() => {
            self.isAnimationPlaying = false;

            self.playAnimation(lootbox, "idle_open", true);
          });

          const cardContainer = document.querySelector(".container");
          const lootContainer = document.querySelector(".loot");
          const card = document.querySelector(".card");
          const staticCards = document.querySelector(".chest-cards");
          const textCongrats = document.querySelector(".textPrize");
          const roundsElement = document.querySelector(".rounds");
          const chestCards = document.querySelector(".chest-cards");
          let currentValue = parseInt(roundsElement.textContent, 10);
          const temporarypng = document.querySelector(".temporarypng");
          if (!isNaN(currentValue) && currentValue > 0) {
            currentValue -= 1;
            roundsElement.textContent = currentValue;
          }
          if (currentValue === 0) {
            this.gameOver = true;
            const tapText = document.querySelector(".tapText");
            const claimButton = document.querySelector(".claimButton");
            tapText.style.display = "none";
            let timeoutDuration = 3500;
            if (this.selectedPullType === "legendary") {
              timeoutDuration = 10000;
            }
            setTimeout(() => {
              cardContainer.classList.add("hide");
              claimButton.classList.remove("hide");
              const canvasElement = document.querySelector(
                "#anime-container canvas"
              );
              this.card1.setVisible(false);
              this.card2.setVisible(false);
              this.roundsText.setVisible(false);

              if (canvasElement) {
                if (this.hasMultiplier) {
                  console.log("Multiplier prize");
                  if (globalDeviceInfo.smallerSide === 834) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 1100,
                      x: lootbox.x - 20,
                      scale: 1,
                      duration: 300,
                      ease: "Linear",
                    });
                  } else if (globalDeviceInfo.smallerSide === 982) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 400,
                      x: lootbox.x - 10,
                      scale: 0.3,
                      duration: 500,
                      ease: "Linear",
                    });
                  } else if (window.innerWidth < 800) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 2900,
                      x: lootbox.x,
                      scale: 1.5,
                      duration: 300,
                      ease: "Linear",
                    });
                  } else if (window.innerWidth <= 1920) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 550,
                      x: lootbox.x - 30,
                      scale: 0.4,
                      duration: 300,
                      ease: "Linear",
                    });
                  } else {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 450,
                      x: lootbox.x - 30,
                      scale: 0.4,
                      duration: 300,
                      ease: "Linear",
                    });
                  }
                } else {
                  if (globalDeviceInfo.smallerSide === 834) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 950,
                      x: lootbox.x - 10,
                      scale: 1,
                      duration: 500,
                      ease: "Linear",
                    });
                  } else if (globalDeviceInfo.smallerSide === 982) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 350,
                      x: lootbox.x - 10,
                      scale: 0.5,
                      duration: 500,
                      ease: "Linear",
                    });
                  } else if (window.innerWidth < 800) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 2000,
                      x: lootbox.x,
                      scale: 2,
                      duration: 300,
                      ease: "Linear",
                    });
                  } else if (window.innerWidth <= 1920) {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 500,
                      x: lootbox.x - 10,
                      duration: 500,
                      ease: "Linear",
                    });
                  } else {
                    self.tweens.add({
                      targets: lootbox,
                      y: lootbox.y - 400,
                      x: lootbox.x - 20,
                      duration: 500,
                      ease: "Linear",
                    });
                  }
                }
              }

              this.displayAllCards();
            }, timeoutDuration);
          }

          card.style.animation = "none";
          card.offsetHeight;
          card.style.animation = "";
          card.style.opacity = "1";
          card.style.visibility = "visible";
          staticCards.style.opacity = "1";
          card.classList.add("object");
          staticCards.classList.add("scale-in-center");
          let isShineEffectAdded = false;
          const isAndroid = document.body.classList.contains("android");

          function addAndRemoveShineEffect(gameObject) {
            gameObject.preFX.addShine(1, 12, 5, false);

            setTimeout(() => {
              gameObject.preFX.clear();
            }, 200);
          }
          if (globalDeviceInfo.smallerSide === 834) {
            if (this.isFirstClick) {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 0, to: 2 },
                alpha: { from: 1, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                  this.isFirstClick = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 0, to: 1 },
                alpha: { from: 0, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            } else {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 2, to: 3 },
                yoyo: true,
                repeat: 0,
                ease: "Sine.easeInOut",
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: [this.card1, this.card2],
                alpha: { from: 1, to: 1 },
                yoyo: true,
                repeat: 1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 1, to: 2 },
                yoyo: true,
                repeat: 0,
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            }
          } else if (window.innerWidth < 1024 && this.sys.game.device.os.iPad) {
            if (this.isFirstClick) {
              console.log("First click animation for iPad");
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 0, to: 3 },
                alpha: { from: 1, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                  this.isFirstClick = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 0, to: 1.9 },
                alpha: { from: 0, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            } else {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 3, to: 5.5 },
                yoyo: true,
                repeat: 0,
                ease: "Sine.easeInOut",
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: [this.card1, this.card2],
                alpha: { from: 1, to: 1 },
                yoyo: true,
                repeat: 1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 1.9, to: 2.9 },
                yoyo: true,
                repeat: 0,
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            }
          } else if (
            globalDeviceInfo.smallerSide === 1024 &&
            this.sys.game.device.os.iPad
          ) {
            console.log("First click animation for iPad bigger than 1024");

            if (this.isFirstClick) {
              console.log("First click animation for iPad");
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 0, to: 3 },
                alpha: { from: 1, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                  this.isFirstClick = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 0, to: 1.9 },
                alpha: { from: 0, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            } else {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 3, to: 5.5 },
                yoyo: true,
                repeat: 0,
                ease: "Sine.easeInOut",
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: [this.card1, this.card2],
                alpha: { from: 1, to: 1 },
                yoyo: true,
                repeat: 1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 1.9, to: 2.9 },
                yoyo: true,
                repeat: 0,
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            }
          } else if (window.innerWidth < 1024 || isAndroid) {
            if (this.isFirstClick) {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 0, to: 8.5 },
                alpha: { from: 1, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                  this.isFirstClick = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 0, to: 2.9 },
                alpha: { from: 0, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            } else {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 8.5, to: 9.5 },
                yoyo: true,
                repeat: 0,
                ease: "Sine.easeInOut",
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: [this.card1, this.card2],
                alpha: { from: 1, to: 1 },
                yoyo: true,
                repeat: 1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 2.9, to: 3.9 },
                yoyo: true,
                repeat: 0,
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            }
          } else {
            if (this.isFirstClick) {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 0, to: 1.1 },
                alpha: { from: 0, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                  this.isFirstClick = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 0, to: 0.8 },
                alpha: { from: 0, to: 1 },
                ease: "Cubic.easeOut",
                duration: 500,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            } else {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 1, to: 1.5 },
                yoyo: true,
                repeat: 0,
                ease: "Sine.easeInOut",
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 0.8, to: 1 },
                yoyo: true,
                repeat: 0,
                duration: 250,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
              addAndRemoveShineEffect(this.card2);
            }
          }

          this.updateRoundsText();
          chestCards.classList.remove("pulsate-fwd");
          void chestCards.offsetWidth;
          chestCards.classList.add("pulsate-fwd");

          if (this.selectedPullType === "legendary" && this.numRounds === 1) {
            if (globalDeviceInfo.smallerSide === 834) {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 2, to: 3 },
                yoyo: true,
                repeat: -1,
                ease: "Sine.easeInOut",
                duration: 100,
                onUpdate: (tween, target) => {
                  if (target.scale > 7.5) {
                    if (!target.isShining) {
                      target.preFX.addShine(1, 12, 5, false);
                      target.isShining = true;
                    }
                  } else {
                    if (target.isShining) {
                      target.preFX.clear();
                      target.isShining = false;
                    }
                  }
                },
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 1, to: 2 },
                yoyo: true,
                repeat: -1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
            } else if (
              globalDeviceInfo.smallerSide === 1024 &&
              this.sys.game.device.os.iPad
            ) {
              console.log("Legendary animation for iPad 34");
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 3, to: 5.5 },
                yoyo: true,
                repeat: -1,
                ease: "Sine.easeInOut",
                duration: 100,
                onUpdate: (tween, target) => {
                  if (target.scale > 9) {
                    if (!target.isShining) {
                      target.preFX.addShine(1, 12, 5, false);
                      target.isShining = true;
                    }
                  } else {
                    if (target.isShining) {
                      target.preFX.clear();
                      target.isShining = false;
                    }
                  }
                },
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 1.9, to: 2.9 },
                yoyo: true,
                repeat: -1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
            } else if (
              window.innerWidth < 1024 &&
              this.sys.game.device.os.iPad
            ) {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 3, to: 5.5 },
                yoyo: true,
                repeat: -1,
                ease: "Sine.easeInOut",
                duration: 100,
                onUpdate: (tween, target) => {
                  if (target.scale > 9) {
                    if (!target.isShining) {
                      target.preFX.addShine(1, 12, 5, false);
                      target.isShining = true;
                    }
                  } else {
                    if (target.isShining) {
                      target.preFX.clear();
                      target.isShining = false;
                    }
                  }
                },
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 1.9, to: 2.9 },
                yoyo: true,
                repeat: -1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
            } else if (window.innerWidth < 1024 || isAndroid) {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 8.5, to: 9.5 },
                yoyo: true,
                repeat: -1,
                ease: "Sine.easeInOut",
                duration: 100,
                onUpdate: (tween, target) => {
                  if (target.scale > 9) {
                    if (!target.isShining) {
                      target.preFX.addShine(1, 12, 5, false);
                      target.isShining = true;
                    }
                  } else {
                    if (target.isShining) {
                      target.preFX.clear();
                      target.isShining = false;
                    }
                  }
                },
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 2.9, to: 3.9 },
                yoyo: true,
                repeat: -1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
            } else {
              this.tweens.add({
                targets: [this.card1, this.card2],
                scale: { from: 1, to: 2 },
                yoyo: true,
                repeat: -1,
                ease: "Sine.easeInOut",
                duration: 100,
                onUpdate: (tween, target) => {
                  if (target.scale > 1.25) {
                    if (!target.isShining) {
                      target.preFX.addShine(1, 12, 5, false);
                      target.isShining = true;
                    }
                  } else {
                    if (target.isShining) {
                      target.preFX.clear();
                      target.isShining = false;
                    }
                  }
                },
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });

              this.tweens.add({
                targets: this.roundsText,
                scale: { from: 0.8, to: 1 },
                yoyo: true,
                repeat: -1,
                duration: 100,
                onComplete: () => {
                  this.isAnimationPlaying = false;
                },
              });
            }
          } else if (
            this.selectedPullType === "legendary" &&
            this.numRounds === 0
          ) {
            if (globalDeviceInfo.smallerSide === 834) {
              this.tweens.killTweensOf([
                this.card1,
                this.card2,
                this.roundsText,
              ]);
              this.card1.clearTint();
              this.card2.clearTint();
              this.roundsText.setScale(1);
              this.card1.setScale(2);
              this.card2.setScale(2);
            } else if (globalDeviceInfo.smallerSide === 982) {
              this.tweens.killTweensOf([
                this.card1,
                this.card2,
                this.roundsText,
              ]);
              this.card1.clearTint();
              this.card2.clearTint();
              this.roundsText.setScale(0.6);
              this.card1.setScale(1);
              this.card2.setScale(1);
            } else if (
              window.innerWidth < 1024 &&
              this.sys.game.device.os.iPad
            ) {
              console.log("Legendary animation for iPad 2");
              this.tweens.killTweensOf([
                this.card1,
                this.card2,
                this.roundsText,
              ]);
              this.card1.clearTint();
              this.card2.clearTint();
              this.roundsText.setScale(1.9);
              this.card1.setScale(3);
              this.card2.setScale(3);
            } else if (
              globalDeviceInfo.smallerSide === 1024 &&
              this.sys.game.device.os.iPad
            ) {
              console.log("Legendary animation for iPad 3");
              this.tweens.killTweensOf([
                this.card1,
                this.card2,
                this.roundsText,
              ]);
              this.card1.clearTint();
              this.card2.clearTint();
              this.roundsText.setScale(1.9);
              this.card1.setScale(3);
              this.card2.setScale(3);
            } else if (
              (window.innerWidth < 1024 || isAndroid) &&
              !this.sys.game.device.os.iPad
            ) {
              this.tweens.killTweensOf([
                this.card1,
                this.card2,
                this.roundsText,
              ]);
              this.card1.clearTint();
              this.card2.clearTint();
              this.roundsText.setScale(2.9);
              this.card1.setScale(8.5);
              this.card2.setScale(8.5);
            } else {
              this.tweens.killTweensOf([
                this.card1,
                this.card2,
                this.roundsText,
              ]);
              this.card1.clearTint();
              this.card2.clearTint();
              this.roundsText.setScale(0.8);
              this.card1.setScale(1);
              this.card2.setScale(1);
            }
          }

          void chestCards.offsetWidth;
          textCongrats.style.animation = "none";
          textCongrats.offsetHeight;
          textCongrats.style.animation = "";
          textCongrats.classList.remove("slide-in-right");
          void textCongrats.offsetWidth;

          if (card_type === "Legendary") {
            textCongrats.classList.add("slide-in-right-delayed");
          } else {
            textCongrats.classList.add("slide-in-right");
          }
        })
        .catch((error) => {
          console.error("Error fetching card data:", error);
          this.isAnimationPlaying = false;
        });
    });

    this.startAnimationSequence(lootbox);
    this.chestCardsDiv = document.querySelector(".chest-cards");
  }

  checkSkipAnimation() {
   
    if (!this.isPremiumAnimationActive) {
      this.isSkipAnimation = document.body.classList.contains("skip-animation");
      
    } else {
      this.isSkipAnimation = false;
     
    }
  }

  showFinalLegendaryAnimation() {
    let finalX,
      finalY,
      finalScale,
      finalTexture,
      finalIconScale,
      finalIconTexture,
      finalTextX,
      finalTextY;

    if (window.innerWidth <= 1024) {
      const lootboxX = this.sys.scale.width / 2;
      const lootboxY = this.sys.scale.height / 2;

      this.legendaryCard = this.add.plane(lootboxX, lootboxY, "legendaryCard");

      this.legendaryCard.setScale(0.5);
      this.legendaryCard.setVisible(true);

      const finalX = this.legendaryCard.x;
      const finalY = this.sys.scale.height / 3 - 600;

      const timeline = this.add.timeline([
        {
          at: 0,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.4,
            scaleY: 0.4,
            y: finalY + 140,
            duration: 20,
            ease: "Cubic.easeOut",
          },
        },
        {
          at: 20,
          tween: {
            targets: this.legendaryCard,
            y: finalY,
            scaleX: 1,
            scaleY: 1,
            duration: 25,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 20,
          tween: {
            targets: this.legendaryCard,
            rotateY: -720,
            duration: 25,
            ease: (t) => {
              return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            },
          },
        },
        {
          at: 45,
          tween: {
            targets: this.legendaryCard,
            scaleX: 1.25,
            scaleY: 0.75,
            duration: 10,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 55,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.75,
            scaleY: 1.25,
            duration: 7,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 62,
          tween: {
            targets: this.legendaryCard,
            scaleX: 1.15,
            scaleY: 0.85,
            duration: 8,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 70,
          run: () => {
            this.legendaryCard.setTexture("newLegendaryImage");

            this.freeSpinsIconF = this.add.image(
              finalX - 840,
              finalY - 15,
              "freeSpinsIcon"
            );
            this.freeSpinsIconF.setScale(0);
            this.freeSpinsIconF.setDepth(this.legendaryCard.depth + 1);

            this.freeSpinsTextF = this.add.text(
              finalX - 840,
              finalY + 380,
              this.won_amount,
              {
                fontFamily: "Berlin Sans FB Demi",
                fontSize: "26px",
                fontStyle: "normal",
                fontWeight: "700",
                color: "#ffffff",
                align: "center",
                lineSpacing: 1,
                letterSpacing: -3,
                stroke: "#000000",
                strokeThickness: 1,
                shadow: {
                  offsetY: 1.843,
                  color: "#000000",
                  blur: 0,
                  stroke: true,
                  fill: true,
                },
              }
            );
            this.freeSpinsTextF.setOrigin(0.5);
            this.freeSpinsTextF.setDepth(this.legendaryCard.depth + 1);
            this.freeSpinsTextF.setScale(0);

            this.tweens.add({
              targets: [this.freeSpinsIconF],
              scale: 0.86,
              duration: 10,
              delay: 400,
              ease: "Back.easeOut",
            });

            this.tweens.add({
              targets: [this.freeSpinsTextF],
              scale: 8,
              duration: 10,
              delay: 400,
              ease: "Back.easeOut",
            });
          },
        },
        {
          at: 70,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.95,
            scaleY: 1.05,
            duration: 10,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 80,
          tween: {
            targets: this.legendaryCard,
            scaleX: 1.05,
            scaleY: 0.95,
            duration: 7,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 87,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.88,
            scaleY: 0.88,
            duration: 10,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 97,
          run: () => {
            if (this.legendaryCard) {
              const screenWidth = this.sys.game.config.width;
              const margin = screenWidth * 0.1;
              const targetX = -margin;
              const currentX = this.legendaryCard.x;
              const distanceToMove = currentX - targetX;

              this.tweens.add({
                targets: [this.legendaryCard],
                x: `-=${distanceToMove}`,
                duration: 500,
                ease: "Power2",
                onComplete: () => {
                  this.activatePremiumAnimation();
                },
              });
            }
          },
        },
      ]);

      timeline.play();
 
      this.isSkipAnimation = false;
    } else {
      const lootboxX = this.cameras.main.width / 2 + 100;
      const lootboxY = this.cameras.main.height / 2 + 260;

      this.legendaryCard = this.add.plane(
        lootboxX,
        lootboxY,
        "legendaryCardDesktop"
      );

      this.legendaryCard.setScale(0.5);
      this.legendaryCard.setVisible(true);

      const finalX = this.legendaryCard.x;
      const finalY = this.sys.scale.height / 3 + 80;

      const timeline = this.add.timeline([
        {
          at: 0,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.4,
            scaleY: 0.4,
            y: finalY + 140,
            duration: 20,
            ease: "Cubic.easeOut",
          },
        },
        {
          at: 20,
          tween: {
            targets: this.legendaryCard,
            y: finalY,
            scaleX: 0.6,
            scaleY: 0.6,
            duration: 25,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 20,
          tween: {
            targets: this.legendaryCard,
            rotateY: -720,
            duration: 25,
            ease: (t) => {
              return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            },
          },
        },
        {
          at: 45,
          tween: {
            targets: this.legendaryCard,
            scaleX: 1.25,
            scaleY: 0.75,
            duration: 10,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 55,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.75,
            scaleY: 1.25,
            duration: 7,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 62,
          tween: {
            targets: this.legendaryCard,
            scaleX: 1.15,
            scaleY: 0.85,
            duration: 8,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 70,
          run: () => {
            this.legendaryCard.setTexture("newLegendaryImage");

            this.freeSpinsIconF = this.add.image(
              finalX - 203,
              finalY,
              "freeSpinsIconDesktop"
            );
            this.freeSpinsIconF.setScale(0);
            this.freeSpinsIconF.setDepth(this.legendaryCard.depth + 1);

            this.freeSpinsTextF = this.add.text(
              finalX - 215,
              finalY + 60,
              this.won_amount,
              {
                fontFamily: "Berlin Sans FB Demi",
                fontSize: "26px",
                fontStyle: "normal",
                fontWeight: "700",
                color: "#ffffff",
                align: "center",
                lineSpacing: 1,
                letterSpacing: -3,
                stroke: "#000000",
                strokeThickness: 1,
                shadow: {
                  offsetY: 1.843,
                  color: "#000000",
                  blur: 0,
                  stroke: true,
                  fill: true,
                },
              }
            );
            this.freeSpinsTextF.setOrigin(0.2);
            this.freeSpinsTextF.setDepth(this.legendaryCard.depth + 1);
            this.freeSpinsTextF.setScale(0);

            this.tweens.add({
              targets: [this.freeSpinsIconF],
              scale: 0.7,
              duration: 10,
              ease: "Back.easeOut",
            });

            this.tweens.add({
              targets: [this.freeSpinsTextF],
              scale: 1,
              duration: 10,
              ease: "Back.easeOut",
            });
          },
        },
        {
          at: 70,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.95,
            scaleY: 1.05,
            duration: 10,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 80,
          tween: {
            targets: this.legendaryCard,
            scaleX: 1.05,
            scaleY: 0.95,
            duration: 7,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 87,
          tween: {
            targets: this.legendaryCard,
            scaleX: 0.7,
            scaleY: 0.7,
            duration: 10,
            ease: "Sine.easeInOut",
          },
        },
        {
          at: 97,
          run: () => {
            this.tweens.add({
              targets: [this.legendaryCard],
              x: "-=200",
              duration: 10,
              ease: "Power2",
              onComplete: () => {
                this.activatePremiumAnimation();
              },
            });
          },
        },
      ]);

      timeline.play();
      this.isSkipAnimation = false;
  
    }
  
  }

  activatePremiumAnimation(targetCard) {
    const premium = this.add.spine(
      targetCard.x,
      targetCard.y + 20,
      "premium-data",
      "premium-atlas"
    );

    premium.setVisible(true);
    premium.setScale(0.26);
    premium.setDepth(targetCard.depth + 1);
    this.playAnimation(premium, "particles4", true);
  }
  startResizeCheck(lootbox) {
    this.resizeCheckInterval = setInterval(() => {
      const screenWidth = window.screen.availWidth;
      const screenHeight = window.screen.availHeight;

      if (
        !this.isDisplayAllCardsInitialized &&
        (window.innerWidth !== this.lastWidth ||
          window.innerHeight !== this.lastHeight ||
          screenWidth !== this.lastScreenWidth ||
          screenHeight !== this.lastScreenHeight)
      ) {
        this.lastWidth = window.innerWidth;
        this.lastHeight = window.innerHeight;
        this.lastScreenWidth = screenWidth;
        this.lastScreenHeight = screenHeight;

        this.resizeLootbox(lootbox);
      }
    }, 1000);
  }

  resizeLootbox(lootbox) {
    if (!lootbox || this.isDisplayAllCardsInitialized) return;

    const centerX = this.cameras.main.width / 2;
    const centerY = this.cameras.main.height / 2;
    let lootboxX, lootboxY, lootboxScale, cameraZoom;

    if (window.innerWidth <= 480) {
      lootboxX = centerX;
      lootboxY = centerY + 950;
      lootboxScale = 3;
      cameraZoom = 0.5;
    } else if (this.sys.game.device.os.iPad) {
      console.log("iPad detected3");
      lootboxX = centerX;
      lootboxY = centerY + 150;
      lootboxScale = 1;
      cameraZoom = 1;
    } else {
      lootboxX = centerX + 100;
      lootboxY = centerY + 260;
      lootboxScale = 0.5;
      cameraZoom = 1;
    }

    lootbox.setPosition(lootboxX, lootboxY);
    lootbox.setScale(lootboxScale);
    this.cameras.main.setZoom(cameraZoom);

    if (this.sys.game.device.os.iPad) {
      this.cameras.main.setZoom(1);
      lootbox.setPosition(centerX, centerY + 150);
    }
  }

  activatePremiumAnimation() {
    this.isPremiumAnimationActive = true;

    this.isSkipAnimation = false;

    let premiumScale, premiumYOffset;

    if (window.innerWidth <= 1024 && !this.sys.game.device.os.iPad) {
      premiumScale = 1.8;
      premiumYOffset = 110;
    } else if (this.sys.game.device.os.iPad) {
      console.log("iPad detected3");
      premiumScale = 0.73;
      premiumYOffset = 40;
    } else {
      premiumScale = 0.26;
      premiumYOffset = 20;
    }

    const premium = this.add.spine(
      this.legendaryCard.x,
      this.legendaryCard.y + premiumYOffset,
      "premium-data",
      "premium-atlas"
    );

    premium.setVisible(true);
    premium.setScale(premiumScale);
    premium.setDepth(this.legendaryCard.depth + 1);
    this.playAnimation(premium, "particles4", true);

    this.premium = premium;
  }
  update() {
    this.checkSkipAnimation();

    if (this.lootbox && !this.isDisplayAllCardsInitialized) {
      console.log("Lootbox position:", this.lootbox.x, this.lootbox.y);
      const x = this.lootbox.x;
      const y = this.lootbox.y;

      this.chestCardsDiv.style.left = `${x}px`;
      this.chestCardsDiv.style.top = `${y}px`;
    }
    this.fpsText.setText("FPS: " + Math.round(this.game.loop.actualFps));
  }

  playDiscoFX(discoFX) {
    discoFX.setVisible(true);
  }

  updateRoundsText() {
    if (this.roundsText) {
      this.roundsText.setText(this.numRounds.toString());
    }
  }

  displayAllCards() {
    this.isDisplayAllCardsInitialized = true;

    const lootDiv = document.querySelector(".loot");
    const calinBtn = document.querySelector(".claimButton");
    lootDiv.innerHTML = "";
    if (this.premium) {
      this.premium.setVisible(false);
      this.legendaryCard.setVisible(false);
      if (this.freeSpinsIcon) {
        this.freeSpinsIcon.setVisible(false);
      }
      if (this.freeSpinsIconF) {
        this.freeSpinsIconF.setVisible(false);
      }
      if (this.freeSpinsText) {
        this.freeSpinsText.setVisible(false);
      }
      if (this.freeSpinsTextF) {
        this.freeSpinsTextF.setVisible(false);
      }
    }
    const lootHeader = document.createElement("div");
    lootHeader.className = "lootheader";
    lootHeader.innerHTML = `
      <div class="titleLoot">Your loot</div>
      <div id="helpButton" class="help" ><span class="question"><img src="/assets/questionm_02.png" class="smallquestion"> </span></div>
    `;
    lootDiv.appendChild(lootHeader);
    const popUp = document.createElement("div");
    popUp.className = "pop-up";
    popUp.innerHTML = `
      <div class="pop-up-content">
        <h2>Help</h2>
        <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book</p>
        <button id="closePopUp">Close</button>
      </div>
    `;
    document.body.appendChild(popUp);

    const helpButton = document.getElementById("helpButton");
    const popContent = document.querySelector(".pop-up-content");
    if (helpButton) {
      helpButton.addEventListener("click", (event) => {
        event.stopPropagation();
        console.log("Help button clicked");
        popUp.style.display = "flex";
        popContent.classList.add("slide-in-elliptic-bottom-fwd");
      });
    } else {
      console.error("Help button not found");
    }

    const closeButton = document.getElementById("closePopUp");
    closeButton.addEventListener("click", () => {
      popUp.style.display = "none";
    });

    popUp.addEventListener("click", (event) => {
      if (event.target === popUp) {
        popUp.style.display = "none";
      }
    });
    const middleDiv = document.createElement("div");
    middleDiv.className = "middle";

    const hasMultiplier = this.openedCards.some(
      (card) => card.prize === "Multiplier"
    );
    if (hasMultiplier) {
      const multiplierDiv = document.createElement("div");
      multiplierDiv.className = "multiplier-loot";
      multiplierDiv.innerHTML = `
        <div class="multiplier-img">
        <div class="card-down">
            <img src="/assets/cards-bg/card_epic.png" class="card-image-multiplier" style="width: 90px!important;">
            <img src="/assets/cards-icons/multiplier.png" class="card-icon" style="width: 90px!important;">
            <div class="card-details-multiplied">
              x5 
            </div>
          </div>
        </div>
        <div class="multiplier-text">
          <div class="multiplier-title">Multiplier</div>
          <div class="multiplier-type">Epic</div>
          <div class="multiplier-value">All wins are multiplied x5</div>
        </div>
      `;
      middleDiv.appendChild(multiplierDiv);
    }

    const cardsDisplay = document.createElement("div");
    cardsDisplay.className = "all-cards-display card-container";
    middleDiv.appendChild(cardsDisplay);

    const cardsToDisplay =
      this.selectedPullType === "multiplier"
        ? this.openedCards.slice(1)
        : this.openedCards;

    cardsToDisplay.forEach((card, index) => {
      const cardElement = document.createElement("div");
      let additionalClass = card.won_amount === "1000" ? "glow-premium" : "";
      let cardImagePath = card.imagePath;
      cardElement.className = `card-down ${additionalClass}`;
      const cardDetailsClass =
        card.won_amount.length > 9
          ? "card-details summary long-text"
          : "card-details summary";
      let iconImagePath = card.iconImagePath;
      console.log("Card type:", card.card_type, "Prize:", card.prize);
      if (card.prize === "Free Spins") {
        console.log("Legendary Free Spins card detected");
        iconImagePath = "/assets/cards-icons/free_spins.png";

        if (additionalClass === "glow-premium") {
          cardImagePath = "/assets/cards-bg/card_legendary.png";
        }
        console.log("Updated iconImagePath:", iconImagePath);
      }
      cardElement.innerHTML = `
        <img src="${cardImagePath}" class="card-image">
        <img src="${iconImagePath}" class="card-icon">
        <div class="${cardDetailsClass}">
          ${card.won_amount} 
        </div>
      `;

      cardsDisplay.appendChild(cardElement);
      setTimeout(() => {
        cardElement.classList.add("fall-in");
        this.sound.play("clap", { volume: 0.5 });
      }, index * 250);
    });

    lootDiv.appendChild(middleDiv);

    this.adjustGridColumns();
    lootDiv.classList.add("hide");
    lootDiv.classList.remove("hide");

    if (window.innerWidth < 600) {
      lootDiv.classList.add("slide-in-bottom");
      calinBtn.classList.add("slide-in-bottom-claim");
    } else if (globalDeviceInfo.smallerSide === 982) {
      lootDiv.classList.add("slide-in-bottom-desktop-multiplier-macbook");
    } else if (window.innerWidth > 2000) {
      if (this.selectedPullType === "multiplier") {
        lootDiv.classList.add("slide-in-bottom-desktop-multiplier-4k");
      } else {
        lootDiv.classList.add("slide-in-bottom-desktop-multiplier-4k-normal");
      }
      calinBtn.classList.add("slide-in-bottom-claim-desktop-4k");
    } else {
      if (this.selectedPullType === "multiplier") {
        lootDiv.classList.add("slide-in-bottom-desktop-multiplier");
      } else {
        lootDiv.classList.add("slide-in-bottom-desktop");
      }
      calinBtn.classList.add("slide-in-bottom-claim-desktop");
    }
    const animationCard = document.querySelector(".animationcard");
    if (animationCard) {
      animationCard.remove();
    }
  }

  adjustGridColumns() {
    const cardsDisplay = document.querySelector(".all-cards-display");

    if (
      this.selectedPullType === "regular" ||
      this.selectedPullType === "uncommon"
    ) {
      cardsDisplay.classList.add("three-cards");
      cardsDisplay.classList.remove("four-cards");
    } else if (
      this.selectedPullType === "multiplier" ||
      this.selectedPullType === "legendary"
    ) {
      cardsDisplay.classList.add("four-cards");
      cardsDisplay.classList.remove("three-cards");
    } else {
      cardsDisplay.classList.remove("three-cards", "four-cards");
      cardsDisplay.style.gridTemplateColumns = `repeat(auto-fill, minmax(90px, 1fr))`;
    }

    const middleDiv = document.querySelector(".middle");
    if (!this.hasMultiplier) {
      middleDiv.style.paddingTop = "0";
    }
  }

  startAnimationSequence(lootbox) {
    this.playAnimation(lootbox, "in", false).then(() =>
      this.playAnimation(lootbox, "idle", true)
    );
  }

  playAnimation(lootbox, animation, loop) {
    return new Promise((resolve) => {
      let trackEntry = lootbox.animationState.setAnimation(0, animation, loop);
      trackEntry.timeScale = 1;
      if (!loop) {
        trackEntry.listener = {
          complete: () => {
            resolve();
          },
        };
      } else {
        resolve();
      }
    });
  }
  fetchAndHandleRounds(pullType) {
    fetch(`https://api-dem.vercel.app/api/rounds/${pullType}`)
      .then((response) => response.json())
      .then((data) => {
        const roundsElement = document.querySelector(".rounds");
        if (roundsElement) {
          roundsElement.textContent = data.rounds;
          this.numRounds = data.rounds;
          this.updateRoundsText();
        }
        console.log(`${pullType} rounds:`, data.rounds);
      })
      .catch((error) =>
        console.error(`Error fetching ${pullType} rounds`, error)
      );
  }

  playAnimationForDuration(lootbox, animation, duration) {
    this.checkSkipAnimation();
    return new Promise((resolve) => {
      lootbox.animationState.setAnimation(0, animation, true);
      if (this.isSkipAnimation) {
        resolve();
      } else {
        setTimeout(() => {
          resolve();
        }, duration);
      }
    });
  }
  playAnimationMultipleTimes(lootbox, animation, times) {
    this.checkSkipAnimation();
    return new Promise((resolve) => {
      if (this.isSkipAnimation) {
        resolve();
      } else {
        const playNext = (count) => {
          if (count >= times) {
            resolve();
            return;
          }
          this.playAnimation(lootbox, animation, false).then(() =>
            playNext(count + 1)
          );
        };
        playNext(0);
      }
    });
  }
}
document.addEventListener("DOMContentLoaded", function () {
  fetch("https://api-dem.vercel.app/api/reset")
    .then((response) => response.json())
    .then((data) => {
      console.log(data.message);
    })
    .catch((error) => console.error("Error resetting counters:", error));
});

document.addEventListener("DOMContentLoaded", function () {
  /**
   * Checks if the user is using an iPad and adds a corresponding class to the body element.
   */
  if (navigator.userAgent.match(/iPad/i)) {
    document.body.classList.add("is-ipad");
  } else {
    document.body.classList.add("not-ipad");
  }

  const muteButton = document.getElementById("muteButton");
  let isMuted = false;

  muteButton.addEventListener("click", () => {
    isMuted = !isMuted;
    muteButton.classList.toggle("muted", isMuted);

    this.sound.mute = isMuted;

    this.sound.getAll().forEach((sound) => {
      sound.setMute(isMuted);
    });
  });

  muteButton.addEventListener("touchstart", (e) => {
    isMuted = !isMuted;
    muteButton.classList.toggle("muted", isMuted);

    this.sound.mute = isMuted;

    this.sound.getAll().forEach((sound) => {
      sound.setMute(isMuted);
    });
    console.log("isMutedmobile", isMuted);
  });
  /**
   * Generates a random number of rounds (7 or 14) and displays them in the element with the 'rounds' class.
   */
  const roundsNumber = Math.random() < 0.5 ? 8 : 8;
  const roundsElement = document.querySelector(".rounds");
  if (roundsElement) {
    roundsElement.textContent = roundsNumber;
  }

  /**
   * Checks if the device is mobile and adjusts the game's width and height accordingly.
   */

  function adjustGameConfig() {
    const isMobile = window.innerWidth < 480;
    let gameWidth = window.innerWidth * window.devicePixelRatio;
    let gameHeight = window.innerHeight * window.devicePixelRatio;
    let scaleMode = Phaser.Scale.ScaleManager;
    console.log("isMobile", isMobile);
    if (isMobile) {
      gameHeight = window.innerHeight * 1.4 * window.devicePixelRatio;
      gameWidth = window.innerWidth * 1.2 * window.devicePixelRatio;
    } else if (window.innerWidth > 480) {
      gameWidth = 1024;
      gameHeight = 768;
      scaleMode = Phaser.Scale.FIT;
    } else {
      gameWidth = window.innerWidth * window.devicePixelRatio;
      gameHeight = window.innerHeight * window.devicePixelRatio;
    }

    return { gameWidth, gameHeight, scaleMode };
  }

  function updateGameScale() {
    const { gameWidth, gameHeight, scaleMode } = adjustGameConfig();

    game.scale.resize(gameWidth, gameHeight);
    game.scale.mode = scaleMode;
    game.scale.refresh();

    const canvas = game.canvas;
    canvas.style.width = `${gameWidth}px`;
    canvas.style.height = `${gameHeight}px`;

    const marginLeft = (window.innerWidth - gameWidth) / 2;
    const marginTop = (window.innerHeight - gameHeight) / 2;
    canvas.style.marginLeft = `${marginLeft}px`;
    canvas.style.marginTop = `${marginTop}px`;
  }
  let { gameWidth, gameHeight, scaleMode } = adjustGameConfig();

  /**
   * Configuration for Phaser.
   */
  const config = {
    width: gameWidth,
    height: gameHeight,
    type: Phaser.WEBGL,
    parent: "anime-container",
    scene: [LootboxSpine],
    transparent: true,
    fps: {
      target: 60,
      forceSetTimeOut: false,
    },
    physics: {
      default: "arcade",
      arcade: {
        debug: true,
      },
    },
    plugins: {
      scene: [
        { key: "SpinePlugin", plugin: spine.SpinePlugin, mapping: "spine" },
      ],
    },
    scale: {
      mode: scaleMode,
      autoCenter: Phaser.Scale.CENTER_BOTH,
    },
    render: {
      pixelArt: false,
      antialias: true,
    },
  };

  const game = new Phaser.Game(config);

  window.addEventListener("resize", () => {
    updateGameScale();
  });

  function handleResize() {
    const dimensions = getGameDimensions();
    game.scale.resize(dimensions.width, dimensions.height);
    game.scale.refresh();
  }

  console.log(game.config);
  document.addEventListener("click", function () {
    if (self.isLegendary) {
      console.log("isLegendary click");
      document.body.classList.add("skip-animation");
      this.animaionSkip = true;
    }
    
  });
});
let globalDeviceInfo;

document.addEventListener("DOMContentLoaded", function () {
  var userAgent = navigator.userAgent || navigator.vendor || window.opera;

  if (/android/i.test(userAgent)) {
    document.body.classList.add("android");
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    document.body.classList.add("ios");
  }

  function detectMobileDevice() {
    const md = new MobileDetect(window.navigator.userAgent);
    const isMobile = md.mobile() !== null;
    const isTablet = md.tablet() !== null;
    const isPhone = md.phone() !== null;

    const smallerSide = Math.min(window.screen.width, window.screen.height);

    let deviceType = "desktop";
    if (isPhone) {
      deviceType = "phone";
    } else if (isTablet) {
      deviceType = "tablet";
    } else if (isMobile) {
      deviceType = "mobile";
    }

    return {
      deviceType: deviceType,
      isMobile: isMobile,
      isTablet: isTablet,
      isPhone: isPhone,
      smallerSide: smallerSide,
    };
  }

  const deviceInfo = detectMobileDevice();
  globalDeviceInfo = detectMobileDevice();

  console.log("Smaller Side (Width):", globalDeviceInfo.smallerSide);
  if (deviceInfo.smallerSide < 768) {
  } else {
  }
});
